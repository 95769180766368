import { InstitutionLogoSize } from 'redux/schemas/models/institution';
import { CompletionCriteria } from 'learning_journeys/components/completion-criteria-dropdown';
import { Name, AutomaticCompletionCriteria } from './courseFull';
import { TeamSet } from './my-account';
import { AnalyticsDashBoardType } from '../app/analytics';
import { SkillTag } from './skill-tag';

/* The different sorting options for the courses list table. See Filters; the string values are sent in the paged data query */
export enum Sorting {
  RELEASE_DATE_DESC = 'release_date_desc',
  RELEASE_DATE_ASC = 'release_date_asc',
  CLOSE_DATE_DESC = 'close_date_desc',
  CLOSE_DATE_ASC = 'close_date_asc',
}

// Generated from /courses.json?institution_id=:id
export interface Course {
  id: number;
  catalogId: string;
  name: string;
  thumbnail: string;
  squareThumbnail: string;
  flyerLogo: string;
  releaseDate: string;
  endDate: null | string;
  createdAt: string;
  inProgress: boolean;
  offeringName: OfferingName;
  catalogName: string;
  officialReleaseDate: string | null;
  closeDate: string;
  // paymentsStartAt: null;
  // paymentsEndAt: null;
  isClosed: boolean;
  version: string;
  released: boolean;
  isProgram: boolean;
  isJourney: boolean;
  isSelfPaced: boolean;
  // classSummary: null;
  headerColor: null;
  enrollmentLimitInDays: number;
  url: string;
  coverPhotoUrl: string;
  largeCoverPhotoURL: string;
  institutionBrandBarFontColor: string;
  // executiveSummary: null;
  attributesForCatalog: AttributesForCatalog;
  // rate: null;
  isPaidAndOpenEnrollment: boolean;
  closedEnrollment: boolean;
  officiallyReleased: boolean;
  numEnrolled: number | null;
  numUnpaid: number;
  numPaid: number;
  numBanned: number;
  numWithdrawn: number;
  nameFontColor: string;
  isNovoedAdmin: boolean;
  typeOfRegistration: CourseRegistrationType;
  setOfFeatures: number;
  usedFor: CourseUsedFor;
  // programID: null;
  creator: Creator;
  parentCourseID: number;
  isBeingDeleted: boolean;
  loginLearners: LoginLearners;
  learnersProgress: LearnersProgress;
  learnersCompleted: LearnersCompleted;
  licensedByInstance: boolean;
  activeLearners: ActiveLearners;
  teamName: OfferingName;
  groupName: OfferingName;
  assignmentName: OfferingName;
  pointsName: OfferingName;
  courseLongTeamSet: TeamSet;
  groupTeamSet: TeamSet;
  courseUserIds: number[];
  logo: null | {
    url: string,
    fileName: string,
  },
  logoSize: InstitutionLogoSize,
  entitlements: Entitlement[],
  collections: Collection[],
  collectionIds: number[],
  description: string | null, // For learning journey
  classDescription: string,
  contentLanguage: string,
  openInJourney: boolean,
  collectionsCount: number,
  coursesCount: number,
  ended: boolean,
  canTakeCourse: boolean,
  headerBackground: string | null,
  paymentAccepted: boolean,
  soaCertificateEnabled: boolean,
  currentLecture: {
    firstLecture: Lecture | null,
    currentLecture: Lecture | null,
  },
  inJourneys: CourseJourneyDetail[],
  completionStats: CompletionStats,
  registrationCloseDate: string | null,
  hasBeenGrantedManualCompletion: boolean,
  automatedCompletionsEnabled: boolean,
  userCourse: number,
  matchingEntitlements: boolean,
  lastActiveDate: string | null,
  automatedTranslationEnabled: boolean,
  isCohort: boolean,
  isPrimary: boolean,
  numCohorts: number | null,
  primary: Omit<Course, 'primary' | 'collections'> | null,
  certificateName: Name,
  lectureName: Name,
  automaticCompletionCriteria?: AutomaticCompletionCriteria,
  gamificationEnabled?: boolean,
  autoEnrollment?: boolean,
  skillTags?: SkillTag[],
  archivedAt?: string,
}

// basic types used to display my learning flyout
export type BasicEnrollment ={
  id: number,
  catalogId: string,
  name: string,
  thumbnail: string,
  closeDate: string,
  headerColor: string,
  inJourneys: FlyoutJourney[],
  isCohort: boolean,
  isPrimary: boolean,
};

// basic types used to display my mentees flyout
export interface BasicMentee extends Omit<BasicEnrollment, 'closeDate' | 'headerColor' | 'inJourneys'> {
  totalNoOfMentees: number,
  menteeAlias: OfferingName,
}

export type BasicMembership = {
  id: number,
  team: BasicTeam,
};

export type BasicTeam = {
  id: number,
  name: string,
  profilePicture: string,
  courseId: number,
  courseName: string,
  courseCatalogId: string
};

export type FlyoutJourney = {
  id: number,
  catalogId: string,
  name: string
};
export interface BasicEnrollmentNormalized { [id: string]: BasicEnrollment }

type Lecture = {
  id: number,
  title: string,
  actionName: string,
  activityId: number,
  happenedAt: string,
  activityType: 'LecturePage',
  activityDeliverableType: null,
};

export type InfiniteLoadingState = {
  currentPage: number;
  loading: boolean;
  loadingMore: boolean;
  firstPageLoaded: boolean;
  hasMore: boolean;
  total?: number;
  query?: string;
  filters?: { [filterKey: string ]: Array<number | string> };
  ids?: number[];
};

export type FeaturedDiscoveryState = {
  offerings: Course[];
  loading: boolean;
};

export interface ActiveLearners {
  learnersActive: number;
  percentage: number | null;
  learners: number[];
}

export interface AttributesForCatalog {
  courseTopic: CourseTopic;
  enrollment: CourseTopic;
  price: Price;
  courseLength: CourseLength;
}

export interface CourseLength {
  is8_Weeks: boolean;
}

export interface CourseTopic {
}

export interface Price {
  isPaid: boolean;
}

export interface Creator {
  firstName: string;
  lastName: string;
  fullName: string;
  userNameForURL: string;
  initials: string;
}

export interface LearnersCompleted {
  learnersCompleted: number;
  percentage: number | null;
}

export interface LearnersProgress {
  inProgress: number;
  percentage: number | null;
}

export interface LoginLearners {
  loggedIn: number;
  percentage: number | null;
}

// TODO: This should be changed to/merged with the "Name" interface in `my-account.ts`
export interface OfferingName {
  capitalizedPluralized: string;
  capitalizedSingularized: string;
  downcasedSingularized: string;
  downcasedPluralized: string;
  pluralizedTitleized: string;
  singularizedTitleized: string;
}

export interface UpdateBrandingSchema {
  brandColor: string,
  name: string,
  brandBarFontColor: '#fff' | '#000',
  // deleteFavicon: boolean,
  flyerLogo: any, // This is binary. TODO: Figure out what this type is
}

/** The types of registration a course supports. The enum values map exactly to
 * the numbered values returned by the backend API. */
export enum CourseRegistrationType {
  FREE_ENROLLMENT = 0,
  PAID_ENROLLMENT = 1,
  FREE_AND_PAID_CERTIFICATE_ENROLLMENT = 2,
  CLOSED_ENROLLMENT = 3,
  FREE_ENROLLMENT_IN_INSTITUTION = 4,
  OPEN_BASED_ON_ENTITLEMENTS = 5,
}

export enum CourseUsedFor {
  PRODUCTION = 0,
  DEMO = 1,
  PRIMARY = 2,
}
export interface CoursesNormalized {
  [id: string]: Course
}

export interface AssignmentAliases {
  AssignmentsAlias: string;
  AssignmentAlias: string;
  assignmentsAlias: string;
  assignmentAlias: string;
  AssignmentsTitleAlias: string;
  AssignmentTitleAlias: string;
}

export interface OfferingAliases {
  CoursesAlias: string;
  CourseAlias: string;
  coursesAlias: string;
  courseAlias: string;
  CoursesTitleAlias: string;
  CourseTitleAlias: string;
}

export interface TeachingTeamAliases {
  TeachingTeamsAlias: string;
  TeachingTeamAlias: string;
  teachingTeamsAlias: string;
  teachingTeamAlias: string;
  TeachingTeamsTitleAlias: string;
  TeachingTeamTitleAlias: string;
}

export interface GroupAliases {
  GroupsAlias: string;
  GroupAlias: string;
  groupsAlias: string;
  groupAlias: string;
  GroupsTitleAlias: string;
  GroupTitleAlias: string;
}

export interface PointAliases {
  PointsAlias: string;
  PointAlias: string;
  pointsAlias: string;
  pointAlias: string;
  PointsTitleAlias: string;
  PointTitleAlias: string;
}

export interface ProgramCourseAliases {
  ProgramCoursesAlias: string;
  ProgramCourseAlias: string;
  programCoursesAlias: string;
  programCourseAlias: string;
  ProgramCoursesTitleAlias: string;
  ProgramCourseTitleAlias: string;
}

export interface TeamAliases {
  TeamsAlias: string;
  TeamAlias: string;
  teamsAlias: string;
  teamAlias: string;
  TeamsTitleAlias: string;
  TeamTitleAlias: string;
}

export interface LearnerAliases {
  LearnersAlias: string;
  LearnerAlias: string;
  learnersAlias: string;
  learnerAlias: string;
  LearnersTitleAlias: string;
  LearnerTitleAlias: string;
}

export interface LectureAliases {
  LecturesAlias: string;
  LectureAlias: string;
  lecturesAlias: string;
  lectureAlias: string;
  LecturesTitleAlias: string;
  LectureTitleAlias: string;
}

export interface CourseAliases {
  assignmentAliases?: AssignmentAliases,
  courseAliases?: OfferingAliases,
  teachingTeamAliases?: TeachingTeamAliases,
  groupAliases?: GroupAliases,
  pointsAliases?: PointAliases,
  programCoursesAliases?: ProgramCourseAliases,
  teamAliases?: TeamAliases,
  learnersAliases?: LearnerAliases,
  lectureAliases?: LectureAliases,
}

export enum TimelineItemTypes {
  SECTION = 'section',
  SUBSECTION = 'subSection',
  PAGE = 'page',
}

export interface TimelineNormalized {
  title: string;
  type: TimelineItemTypes;
  id: number;
}

export type MinimalCourse = {
  catalog_id: string
  id: number
  name: string
};

export interface Entitlement {
  id: number,
  values: string[],
  profileSettingId: number,
}

export interface Collection {
  id: number,
  name: string,
  description: string,
  numRequiredCourses: number,
  completionSettings: CompletionCriteria,
  courses?: Course[],
  courseIds?: number[],
}

export interface CollectionDirectory {
  [key: string]: Collection,
}

export type CourseJourneyDetail = {
  id: number;
  catalogId: string;
  name: string;
  releaseDate: string;
  closeDate: string;
  headerColor: string;
  nameFontColor: string;
  headerBackground: string;
  completionStats: Omit<CompletionStats, 'collections'>;
};

export type CollectionCompletionStats = {
  id: number;
  required: number;
  completed: number;
  total: number;
  completionSettings: CompletionCriteria,
};

export type CompletionStats = {
  completed: number;
  total: number;
  required: number;
  collections: CollectionCompletionStats[];
};

export type QuestionSet = {
  id: number;
  title: string;
  lecturePageId: number;
};

export enum QuestionSetKey {
  TIMED_EXAMS = 'timed_exams',
  SURVEYS = 'surveys',
  QUIZZES = 'quizzes',
}

export const questionSetkeyMapping = {
  TimedExam: 'TIMED_EXAM',
  Quiz: 'QUIZ',
  Survey: 'SURVEY',
};

export type AnalyticsState = {
  questionSets?: { [key in QuestionSetKey]: QuestionSet[] }
  practiceList?: {
    id: number;
    title: string;
  }[]
  questionSetsLoaded?: boolean
  isPracticeListLoaded?: boolean
};

export type QrveyState = {
  isLoading: boolean;
  course?: QrveyInstance,
  cohort?: QrveyInstance,
  journey?: QrveyInstance,
  license?: QrveyInstance,
  scenarioAnalytics?: QrveyInstance,
  institution?: QrveyInstance,
  questionSet?: QrveyInstance
  type: AnalyticsDashBoardType;
};
export type QrveyPanel = {
  title?: string,
  chartId: string,
  qrveyid: string,
  dataSetId: string,
  userFilters?: any,
  chartType?: 'Comparison' | 'Question' | 'All'
  isFullPanel?: boolean
};

export type QrveyInstance = {
  token: string;
  appId: string;
  userId: string;
  host: string;
  journeyName? : string;
  pageId? : string;
  pageIds? : string[];
  userFilters?: any;
  topPageId?: string
  panelsPageId?: string
  questionSetTitle?: string
  panels: QrveyPanel[]
};

export type QrveyConfig = {
  qv_token: string;
  domain: string;
  app_id: string;
  user_id: string;
  page_id? : string;
  customCSSRules: string;
  custom_styles: boolean;
  automaticDownload: any;
  pageIds? : any;
  downloadingBoxText?: string;
  userFilters?: any;
  top_page_id?: string;
  panels_page_id?: string;
  dashboardId?: string;
  styles?: {
    main_color?: string
    main_text_color?: string
    secondary_color?: string
    icon_color?: string
    tab_bar_color?: String
    tab_font_color?: string
    error_color?: string
  }
  featurePermission?: {
    panels: {
      global: {
        hide_CSV: boolean,
      },
    },
  },
};

export type AutoEnrollmentInJourney = {
  courseId: number;
  userId: number;
  journeyId: number;
};

export const PUSHER_JOURNEY_AUTO_ENROLLMENT = 'auto_enrollment_in_journey';
